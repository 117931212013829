<template>
  <div class="login-wrapper">
    <div class="sign-in-form-wrapper">
      <div class="sign-in-form-wrapper__back-img" @click="$router.push('/')">
        <img src="@/assets/auth/back.svg" alt="svg">
      </div>

      <div class="sign-in-form">
        <div class="sign-in-form__title">Войти в систему</div>
        <div v-if="isShow" class="sign-in-form__description-user">
          Спасибо за покупку курса, войдите в ваш аккаунт,
          чтобы получить к нему доступ
        </div>
        <div v-else class="sign-in-form__description">
          Добро пожаловать<br> на нашу образовательную платформу
        </div>

        <form class="sign-in-form__form" @keypress.enter.prevent="handleSubmitForm">

          <form-input class="sign-in-form__form-input"
                      style="margin-bottom: 24px"
                      v-model="form.email"
                      type="text"
                      label="E-mail"
                      :validator="$v.form.email"
                      placeholder="Введите свой e-mail"/>

          <form-input class="sign-in-form__form-input"
                      v-model="form.password"
                      :validator="$v.form.password"
                      label="Пароль"
                      type="password"
                      placeholder="Введите пароль"/>

        </form>

        <div class="sign-in-form__forgot-text" @click="$refs.emailModal.openModal()">Забыли пароль?</div>

        <div class="sign-in-form__form-btn" @click="handleSubmitForm">
          <div v-if="!loading" class="sign-up-form__form-btn-title">
            Войти в систему
          </div>
          <i v-else class="el-icon-loading"></i>
        </div>

<!--        <div class="sign-in-form__login-with-text">или войдите с помощью</div>-->

<!--        <socials/>-->

        <div v-if="!isShow" class="sign-in-form__footer">
          <div class="sign-in-form__footer-text">У вас еще нет аккаунта?</div>
          <div class="sign-in-form__footer-text-registration"
               @click="$emit('changeTypeForm')">
            Зарегистрироваться
          </div>
        </div>
      </div>
    </div>
    <email-modal ref="emailModal"/>
  </div>
</template>

<script>
import FormInput from "../../components/FormInput";
import EmailModal from "../password-recovery/modal/EmailModal";

import {validationMixin} from "vuelidate";
import {required, minLength, maxLength, email} from 'vuelidate/lib/validators'
import {validate} from "../../../../utils/validators";
import imgToSvg from "../../../../assets/js/imgToSvg";
import {authService} from "../auth.service";

export default {
  name: "SignInForm",
  mixins: [validationMixin],
  components: {
    FormInput,
    EmailModal
  },

  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      loading: false
    }
  },
  computed:{
    userInfo(){
      return JSON.parse(localStorage.getItem('userInfo'))
    },
    isNewUser() {
      return this.userInfo && this.userInfo.isTemporary
    },
    isShow(){
      return this.$store.state.payment.flagPayment && this.userInfo && !this.userInfo.isTemporary

    }
  },
  beforeCreate() {
    imgToSvg('.sign-in-form__form-input img')
  },
  mounted() {
    if(this.userInfo && this.userInfo.email)
      this.form.email = this.userInfo.email
  },
  methods: {
    handleSubmitForm() {
      if (validate(this.$v)) {
        this.loading = true
        authService.auth(this.form).then(() => {
          this.$router.push('/profile')
          localStorage.removeItem('userInfo')
        }).finally(() => {
          this.loading = false
        })
      }
    }
  },
  validations: {
    form: {
      email: {required, email: email},
      password: {required, minLength: minLength(6), maxLength: maxLength(64)},
    }
  }
}
</script>

<style lang="scss">
.login-wrapper {
  display: flex;
  max-width: 600px;
  width: 100%;

  .sign-in-form-wrapper {
    display: flex;

    width: 100%;
    padding-top: 42px;

    &__back-img {
      display: flex;
      align-items: center;

      height: 50px;

      margin-right: 20px;
      margin-left: 20px;
      cursor: pointer;

      transition: 0.2s;
    }
  }


  .sign-in-form {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding-right: 70px;
    padding-left: 20px;

    transition: 0.2s;

    &__title {
      font-family: Raleway, sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 50px;
      letter-spacing: 0em;
      text-align: center;

      margin-bottom: 6px;
      transition: 0.2s;
    }

    &__description {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;

      margin-bottom: 38px;
      margin-top: 6px;
      transition: 0.2s;
    }

    &__description-user {
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
      color: #1454F2;

      margin-bottom: 38px;
      margin-top: 6px;
      transition: 0.2s;
    }

    &__form {

    }

    &__form-input {
      margin: 0px 0px;

      .form-input__input {
        height: 48px;
        border-radius: 100px;
        padding-left: 30px;

        &::placeholder {
          font-family: Montserrat, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .form-input__label {
        span {
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: left;

          margin-left: 30px;
        }
      }

      .form-input__eye {
        position: absolute;
        right: 13px;
        bottom: 15px;
        cursor: pointer;
      }
    }

    &__forgot-text {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;

      width: max-content;
      margin-left: auto;

      margin-top: 10px;

      cursor: pointer;
    }

    &__login-with-text {
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: center;

      margin-bottom: 8px;
    }

    &__form-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none !important;

      height: 48px;

      background: #1454F2;
      border-radius: 50px;

      cursor: pointer;
      margin: 20px 0px;

      color: #FFFFFF;

      transition: 0.2s;
      &:hover {
        background: #1454f2de;
      }
    }

    &__form-btn-title {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #FFFFFF;

      margin-right: 15px;
    }

    &__footer {
      display: flex;
      justify-content: center;

      margin-top: 20px;
      margin-bottom: 52px;
    }

    &__footer-text {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;

      margin-right: 12px;
    }

    &__footer-text-registration {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;

      color: #407BFF;
      cursor: pointer;
    }
  }

}

@media screen and (max-width: 1200px) {
  .sign-in-form-wrapper__back-img {
    margin-left: 0 !important;
    margin-right: 10px !important;
  }

  .login-wrapper {
    .sign-in-form {
      padding-right: 50px !important;
    }
  }
}
@media screen and (max-width: 980px) {

  .sign-in-form {
    padding-right: 45px !important;
    transition: 0.2s;
  }
  .login-wrapper .sign-in-form-wrapper__back-img {
    margin-right: 0px !important;
    margin-left: 20px !important;
  }
}

@media screen and (max-width: 860px) {
  .sign-in-form {
    width: 320px !important;
    padding-right: 15px !important;
    transition: 0.2s;
  }
}

@media screen and (max-width: 720px) {
  .sign-in-form {
    width: 280px !important;
    padding-right: 10px !important;
  }
  .login-wrapper .sign-in-form-wrapper__back-img {
    margin-right: 15px !important;
  }
}


@media screen and (max-width: 690px) {
  .sign-in-form {
    width: 100% !important;
    padding-right: 80px !important;
  }
  .login-wrapper .sign-in-form-wrapper__back-img {
    margin-right: 45px !important;
    margin-left: 15px !important;
  }
}

@media screen and (max-width: 510px) {
  .login-wrapper {
    .sign-in-form {
      width: auto !important;
      padding-right: 20px !important;
      padding-left: 0 !important;
    }
  }
  .login-wrapper .sign-in-form-wrapper__back-img {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 450px) {

  .sign-in-form__footer{
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 48px!important;
  }
}

@media screen and (max-width: 410px) {

  .sign-in-form {
    padding-right: 15px !important;
  }
}

@media screen and (max-width: 350px) {

  .login-wrapper .sign-in-form-wrapper__back-img {
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 320px) {

  .sign-in-form__title{
    font-size: 18px !important;
  }
  .sign-in-form__description{
    font-size: 14px !important;
  }
}


</style>