<template>
  <div class="email-modal">
    <el-dialog :visible="isVisible" :before-close="closeModal">

      <template slot="title">
        <div class="email-modal__header">
          <div class="email-modal__header-title">Забыли свой пароль?</div>
        </div>
      </template>

      <div class="email-modal__content">
        <div class="email-modal__content-text">Пожалуйста, введите свой e-mail</div>
        <div style="width: 100%">
          <form v-loading="loading" @submit.prevent="handleSubmit"
                class="email-modal__content-input">
            <form-input label="E-mail"
                        class="email-modal__email-input"
                        v-model.trim="email"
                        :validator="$v.email"
                        maxLength="64"
                        placeholder="Введите электронную почту"/>

            <button class="email-modal__btn"
                 @submit="handleSubmit">
              Продолжить
            </button>

          </form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FormInput from "../../../components/FormInput";

import {validationMixin} from "vuelidate";
import {email, required} from 'vuelidate/lib/validators'
import {validate} from "../../../../../utils/validators";
import {authService} from "../../auth.service";

export default {
  name: "EmailModal",
  mixins: [validationMixin],
  components: {
    FormInput
  },
  data() {
    return {
      email: '',
      loading: false,
      isVisible: false
    }
  },
  methods: {
    openModal: function () {
      this.email = ''
      this.isVisible = true
    },
    closeModal: function () {
      this.email = ''
      this.isVisible = false
      this.loading = false

      this.$v.$reset()
    },
    handleSubmit() {
      if (validate(this.$v) && !this.loading) {
        this.loading = true
        authService.sendNewPassword(this.email)
        .then(()=> this.closeModal())
      }
    },
  },

  validations: {
    email: {required: required, email: email},
  }
}
</script>

<style lang="scss">
.email-modal {
  .el-dialog {
    border-radius: 20px;
    width: 550px;
  }

  .el-dialog__headerbtn {
    .el-dialog__close{
      color: #121212;
      font-weight: 600;
    }

  }

  &__header {
    display: flex;
    justify-content: center;
  }

  &__header-title {
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;


    width: 290px;
    padding-bottom: 12px;

    border-bottom: 2px solid #1454F2;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content-text {
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;

    color: #121212;

    margin-bottom: 48px;
  }

  &__content-input {
    display: flex;
    align-items: center;
    flex-direction: column;

    .form-input__error-text {
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  &__email-input {
    margin-bottom: 32px;
    width: 100%;

    .form-input__label {
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;

      span {
        margin-bottom: 5px;
      }
    }

    .form-input__input {
      font-family: Raleway, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 4px;


      &::placeholder {
        font-size: 16px;
      }
    }

  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;

    width: 300px;
    height: 58px;

    background: #1454F2;
    border-radius: 50px;

    cursor: pointer;

    transition: 0.2s;
    &:hover {
      background: #1454f2de;
    }
  }
}

@media screen and (max-width: 900px) {
  .email-modal {
    .el-dialog {
      border-radius: 20px;
      width: 450px;
      transition: 0.2s;
    }
    .email-modal__content-text{
      margin-bottom: 30px;
      transition: 0.2s;
    }
    .email-modal__email-input{
      margin-bottom: 20px;
      transition: 0.2s;
    }
    .el-dialog__body{
      padding-top: 10px;
      transition: 0.2s;
    }
  }
}

@media screen and (max-width: 500px) {
  .email-modal {
    .el-dialog {
      border-radius: 20px;
      width: 370px;
      transition: 0.2s;
    }
    .email-modal__content-text{
      margin-bottom: 10px;
      transition: 0.2s;
    }
    .email-modal__email-input{
      margin-bottom: 10px;
      transition: 0.2s;
    }
    .el-dialog__body{
      padding-top: 0px;
      padding-bottom: 15px;
      transition: 0.2s;
    }
  }
}

@media screen and (max-width: 370px) {
  .email-modal {
    .el-dialog {
      border-radius: 20px;
      width: 330px;
      transition: 0.2s;
    }
    .email-modal__content-text{
      margin-bottom: 10px;
      transition: 0.2s;
    }
    .email-modal__email-input{
      margin-bottom: 10px;
      transition: 0.2s;
    }
    .el-dialog__body{
      padding-top: 0px;
      padding-bottom: 15px;
      transition: 0.2s;
    }
  }
}

</style>