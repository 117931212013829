<template>
  <div class="logo-wrapper">
    <div class="logo-wrapper__title">
      Выбирайте курс по своим интересам и делайте свою жизнь интереснее и лучше
    </div>
    <div class="logo-wrapper__logo-wrapper">
      <router-link :to="'/'" class="logo-wrapper__logo">
        <img src="@/assets/img/auth-user/omybrain.svg" alt="logo">
      </router-link>

      <img class="logo-wrapper__icon" src="@/assets/img/auth-user/icon-one.svg" alt="icon">
      <img class="logo-wrapper__icon" src="@/assets/img/auth-user/icon-two.svg" alt="icon">
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoWrapper",
}
</script>

<style lang="scss" scoped>
.logo-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 600px;
  width: 100%;

  min-height: 755px;

  background: #407BFF;

  &__title {
    font-family: Raleway, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;

    margin: 50px 0;
    color: #FFFFFF;

    max-width: 374px;
  }

  &__logo-wrapper {
    position: relative;
    width: 530px;

    .logo-wrapper__icon:last-child {
      position: absolute;
      top: 130px;
      right: 0;
    }

    .logo-wrapper__icon:nth-child(2n) {
      position: absolute;
      bottom: 70px;
      left: 0;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 430px;
    width: 460px;

    background: #FFFFFF;
    border-radius: 20px;

    margin: 0 auto;

    img {
      width: 90%;
    }
  }

}
@media screen and (max-width: 1080px) {
  .logo-wrapper__logo {
    width: 370px;
    transition: 0.2s;
  }
  .logo-wrapper__logo-wrapper{
    width: 435px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 980px) {
  .logo-wrapper__logo {
    width: 370px;
    transition: 0.2s;
  }
  .logo-wrapper__logo-wrapper{
    width: 435px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 780px) {
  .logo-wrapper__logo {
    width: 270px;
    transition: 0.2s;

    img{
      width: 270px;
    }
  }
  .logo-wrapper__logo-wrapper{
    width: 370px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 690px) {
  .logo-wrapper{
    display: none;
  }
}

</style>