<template>
  <div class="container">
    <div class="auth-wrapper">
      <transition name="fade">
        <sign-in-form v-if="typeForm" @changeTypeForm="typeForm = !typeForm"/>
        <sign-up-form v-else-if="!isNewUser" @changeTypeForm="typeForm = !typeForm"/>
      </transition>
      <logo-wrapper></logo-wrapper>
    </div>
  </div>

</template>

<script>
import LogoWrapper from "./forms/components/LogoWrapper";
import SignUpForm from "./forms/SignUpForm";
import SignInForm from "./forms/SignInForm";


export default {
  name: "AuthWrapper",
  components: {
    LogoWrapper,
    SignUpForm,
    SignInForm
  },
  data(){
    return{
      typeForm:true
    }
  },
  computed:{
    isNewUser(){
      return this.$store.state.payment.flagPayment && JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {
    if(this.$store.state.user.isLoggedIn) {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-wrapper{
  display: flex;
}
</style>