<template>
  <div class="login-wrapper">
    <div class="sign-up-form-wrapper">
      <div class="sign-up-form-wrapper__back-img"><img  @click="$router.go(-1)" src="@/assets/auth/back.svg" alt="svg"></div>

      <div class="sign-up-form">
        <div class="sign-up-form__title">Зарегистрироваться</div>
        <div class="sign-up-form__description">Добро пожаловать на нашу образовательную платформу</div>

        <form class="sign-up-form__form" @keypress.enter.prevent="handleSubmitForm">

          <form-input class="sign-up-form__form-input"
                      v-model="form.email"
                      type="text"
                      label="E-mail *"
                      :validator="$v.form.email"
                      placeholder="Введите свой e-mail"/>
          <form-input class="sign-up-form__form-input"
                      v-model="form.password"
                      :validator="$v.form.password"
                      label="Пароль *"
                      type="password"
                      placeholder="Введите пароль"/>

          <div class="sign-up-form__repeat-password">
            <div class="sign-up-form__repeat-password-error">{{correctPassword}}</div>
            <form-input class="sign-up-form__form-input"
                        :class="{'form-input--error': correctPassword}"
                      v-model="repeatPassword"
                      :validator="$v.form.password"
                      type="password"
                      placeholder="Подтвердите пароль"/>
          </div>

          <form-input class="sign-up-form__form-input"
                      v-model="form.fullName"
                      :validator="$v.form.fullName"
                      label="Фамилия Имя *"
                      type="text"
                      placeholder="Фамилия Имя"/>

          <div class="sign-up-form__date-picker">
            <label class="sign-up-form__title-date-picker" for="date-picker">Дата рождения</label>
            <el-date-picker
                popper-class="user-date-birthday"
                type="date"
                id="date-picker"
                v-model="form.birthday"
                placeholder="Выберите дату рождения"
                :picker-options="pickerOptions">
            </el-date-picker>
          </div>

          <form-input class="sign-up-form__form-input"
                      v-model="form.phoneNumber"
                      type="text"
                      label="Номер телефона *"
                      :mask="'+7 (###) ### ## ##'"
                      :validator="$v.form.phoneNumber"
                      placeholder="+7 (904) --- -- --"/>

        </form>

        <div class="sign-up-form__form-btn" @click="handleSubmitForm">
          <div v-if="!loading" class="sign-up-form__form-btn-title">Зарегистрироваться</div>
          <i v-else class="el-icon-loading"></i>
        </div>
<!--        <div class="sign-in-form__login-with-text">или</div>-->

<!--        <socials type-form="up"/>-->

        <div class="sign-up-form__footer">
          <div class="sign-up-form__footer-text">У вас уже есть аккаунт?</div>
          <div class="sign-up-form__footer-text-registration"
               @click="$emit('changeTypeForm')">
                Войти
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from "../../components/FormInput";

import {validationMixin} from "vuelidate";
import {required, minLength, maxLength, email} from 'vuelidate/lib/validators'
import {validate} from "../../../../utils/validators";
import {authService} from "../auth.service";

export default {
  name: "SignUpForm",
  mixins: [validationMixin],
  components: {
    FormInput,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        fullName:'',
        birthday:null,
        phoneNumber:''
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      repeatPassword: '',
      flagError: false,
      loading:false,
    }
  },
  computed:{
    correctPassword(){
      if(this.form.password !== this.repeatPassword && this.form.password.length && this.flagError)
        return 'Пароль не совпадает'
      else
        return ''
    }
  },
  methods: {
    async handleSubmitForm() {
      this.flagError = true

      if(validate(this.$v) && this.form.password === this.repeatPassword){
        this.loading = true

        if(this.form.birthday) {
          const dateBirthday = new Date(this.form.birthday.toString())
          this.form.birthday = `${dateBirthday.getFullYear()}-${dateBirthday.getMonth() + 1}-${dateBirthday.getDate()}`
        }
        else
          delete this.form.birthday

        await authService.register(this.form).finally(()=> {
          this.loading = false
          this.flagError = false
        })
        await authService.auth({email:this.form.email,password:this.form.password}).then(()=>{
          this.$router.push('/profile')
        })
      }
    },
  },
  validations: {
    form: {
      email: {required, email: email},
      password: {required, minLength: minLength(6), maxLength: maxLength(64)},
      fullName: {required, minLength: minLength(1), maxLength: maxLength(40)},
      phoneNumber: {required, phoneRu: minLength(18)}
    },
  }
}
</script>

<style lang="scss">
.login-wrapper {
  display: flex;
  max-width: 600px;
  width: 100%;

  .sign-up-form-wrapper {
    display: flex;

    width: 100%;
    padding-top: 10px;

    &__back-img {
      display: flex;
      align-items: center;

      height: 50px;

      margin-right: 46px;
      cursor: pointer;
    }

  }


  .sign-up-form {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding-right: 50px;

    transition: 0.2s;

    &__date-picker{
      margin:0 auto;
      max-width: 450px;
    }
    .el-date-editor{
      width: 100%;

      margin-bottom: 14px;
      margin-top: 2px;

      .el-input__inner{
        height: 48px;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        border: 0.5px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 100px;
        transition: 0.2s;

        &:focus {
          border-color: #7B8DFF;
        }

        &::placeholder{
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;

        }
      }
    }
    &__title-date-picker{
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;


      color: #000000;
      margin-left: 30px;
    }

    &__title {
      font-family: Raleway, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 50px;
      letter-spacing: 0em;
      text-align: center;

      transition: 0.2s;
    }

    &__description {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;

      margin-bottom: 14px;
      margin-top: 6px;
      transition: 0.2s;
    }

    &__form{
      .sign-up-form__form-input:last-child{
        margin-bottom: 10px;
      }
    }
    &__form-input {
      margin: 0 auto;
      margin-bottom: 14px;
      max-width: 450px;

      .form-input__input {
        height: 48px;
        border-radius: 100px;
        padding-left: 30px;

        &::placeholder {
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .form-input__label {
        span {
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: left;

          margin-left: 30px;
          margin-bottom: 6px;
        }
      }

      .form-input__eye {
        position: absolute;
        width: 20px;
        height: 18px;
        right: 13px;
        bottom: 15px;
        cursor: pointer;
      }
    }

    &__repeat-password{
      position: relative;
      margin-bottom: 14px;

      &-error{
        position: absolute;
        right: 0px;
        top: -12px;

        margin-left: auto;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: #FF0000;
      }
    }

    &__login-with-text {
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: center;

      margin-bottom: 12px;
    }

    &__form-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none !important;

      height: 48px;

      margin: 20px 0px;

      background: #1454F2;
      border-radius: 50px;

      cursor: pointer;

      color: #FFFFFF;

      transition: 0.2s;
      &:hover {
        background: #1454f2de;
      }
    }

    &__form-btn-title{
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #FFFFFF;

      margin-right: 15px;
    }

    &__footer {
      display: flex;
      justify-content: center;

      margin-top: 20px;
      margin-bottom: 16px;
    }

    &__footer-text {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;

      margin-right: 12px;
    }

    &__footer-text-registration {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;

      color: #407BFF;
      cursor: pointer;
    }
  }
}

.user-date-birthday{

  .el-date-picker__header{
    font-family: Montserrat,sans-serif;
  }
  .el-picker-panel__content{
    font-family: Montserrat,sans-serif;
  }
}

@media screen and (max-width: 920px) {
  .sign-up-form {
    padding-right: 45px !important;
  }
  .sign-up-form-wrapper__back-img {
    margin-right: 0px !important;
    transition: 0.2s;
  }
}


@media screen and (max-width: 860px) {
  .sign-up-form {
    width: 320px !important;
    transition: 0.2s;
  }
}

@media screen and (max-width: 720px) {
  .sign-up-form {
    width: 280px !important;
    padding-right: 10px !important;
  }
  .login-wrapper .sign-in-form-wrapper__back-img {
    margin-right: 15px !important;
  }
}


@media screen and (max-width: 690px) {
  .sign-up-form {
    width: 100% !important;
    padding-right: 80px !important;
  }
  .login-wrapper .sign-in-form-wrapper__back-img {
    margin-right: 45px !important;
    margin-left: 15px !important;
  }
}

@media screen and (max-width: 470px) {
  .sign-up-form {
    padding-right: 10px !important;
  }
}

@media screen and (max-width: 320px) {

  .sign-up-form__title{
    font-size: 18px !important;
  }
  .sign-up-form__description{
    font-size: 14px !important;
  }
}
</style>
